import {
  BuildType,
  CreateResourceAlphaInput,
  DeployTarget,
  PortProtocol,
} from "@zeet/web-api/dist/graphql";
import config from "~/utils/config";

const SAMPLE_PROJECT_NAME = "my-first-zeet-project";

export const SAMPLE_PROJECT_STATUS = "Sample Project";

export const isSampleProject = (projectName?: string) => {
  return projectName === SAMPLE_PROJECT_NAME;
};

export const makeCreateSampleProjectInput = (
  teamId: string,
  clusterId: string
): CreateResourceAlphaInput => {
  return {
    userID: teamId,
    name: SAMPLE_PROJECT_NAME,
    projectName: "my-first-group",
    environmentName: "my-first-sub-group",
    blueprintID: config.CONTAINER_BLUEPRINT_ID,
    source: {
      git: {
        repository: "https://github.com/zeet-demo/my-first-zeet-project",
      },
    },
    build: {
      build: {
        buildType: BuildType.Node,
        buildCommand: "npm --production=false install",
        nodejsVersion: "18",
        runCommand: "npm start",
      },
    },
    kubernetes: {
      deployTarget: {
        deployTarget: DeployTarget.Kubernetes,
        clusterID: clusterId,
      },
      app: {
        deployJob: false,
        deployService: true,
        volumes: [],
        envs: [],
        ports: [
          {
            port: "3000",
            protocol: PortProtocol.Tcp,
            public: true,
            https: true,
          },
        ],
        resources: {
          cpu: 1,
          memory: 1,
          spot: false,
        },
      },
    },
    enabled: true,
  };
};
